/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from './index.module.css'
export default function Landing_1() {
  return (
    <section className='relative'>
      <div className='relative mx-auto max-w-[700px]'>
        <img
          src='/assets/images/landing/bg-landing-1-v2.jpg'
          alt='Background'
          title='Background'
          width='700'
          height='2100'
          className='bg-black'
        />
        <a
          href='https://t.me/sinoent'
          title='고객센터 텔레그램 바로가기'
          target='_blank'
          className={`absolute top-[23.5%] left-1/2 -translate-x-1/2 w-[60%] max-w-[398px] ${styles['shadow']}`}
          rel='noreferrer'
        >
          <img
            src='/assets/images/landing/telegram-btn.png'
            alt='고객센터 텔레그램 바로가기'
            title='고객센터 텔레그램 바로가기'
            width='1135'
            height='232'
            className='w-full object-cover'
          />
        </a>
        <a
          href='https://youtube.com/@casinomaximnew'
          title='지노엔터 맥심 YOUTUBE 바로가기'
          target='_blank'
          className={`absolute top-[49.5%] left-1/2 -translate-x-1/2 w-[70%] max-w-[450px] ${styles['shadow']}`}
          rel='noreferrer'
        >
          <img
            src='/assets/images/landing/youtube-btn.png'
            alt='지노엔터 맥심 YOUTUBE 바로가기'
            title='지노엔터 맥심 YOUTUBE 바로가기'
            width='1293'
            height='232'
            className='w-full object-cover'
          />
          <div className='blur-sm bg-white w-[1px] h-[1px] absolute rotate-180'></div>
        </a>
      </div>
      <div className='gradient'></div>
    </section>
  )
}
