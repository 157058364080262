import { Navigate, Outlet, useRoutes } from 'react-router-dom'

// eslint-disable-next-line import/default, import/namespace, import/no-named-as-default, import/no-named-as-default-member
import path from 'src/constants/path'
import { Landing_1, Landing_2 } from 'src/layouts/LandingLayout'
import Battle from 'src/pages/Battle'
import BattleCasino from 'src/pages/Battle/BattleCasino'
import BetGames from 'src/pages/Battle/BetGames'
import Minigame from 'src/pages/Battle/Minigame'
import Sports from 'src/pages/Battle/Sports'
import VirtualSports from 'src/pages/Battle/VirtualSports'
import Event from 'src/pages/Event'
import ListPoint from 'src/pages/ListPoint'
import MoveMoney from 'src/pages/MoveMoney'
import NewBoard from 'src/pages/NewBoard'
import Note from 'src/pages/Note'
import WithDraw from 'src/pages/WithDraw'
import MainLayout from '../layouts/MainLayout'
import Casino from '../pages/Casino'
import Chat11 from '../pages/Chat11'
import HomePage from '../pages/HomePage'
import ReCharge from '../pages/ReCharge'
import ProtectedRoute from './ProtectedRoute'

export default function Router() {
  const routeElements = useRoutes([
    {
      path: '',
      element: <Landing_1 />
    }
  ])

  return routeElements
}
